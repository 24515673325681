
.hidden-mobile {
  display: none !important;
}

.hidden-desktop {
  display: none !important;
}

@media screen and (max-width: 600px) {
  html body .body-container {
    &.toolbar {
      min-height: calc(100vh - 56px);

      @supports (height: 100dvh) {
        min-height: calc(100dvh - 56px);
      }
    }
  }

  .card-full {
    min-height: 100vh;

    @supports (height: 100dvh) {
      min-height: 100dvh;
    }

    &.toolbar {
      min-height: calc(100vh - 56px);

      @supports (height: 100dvh) {
        min-height: calc(100dvh - 56px);
      }
    }
  }

  html .card-full.mdc-card {
    border-radius: 0;
  }

  html .mat-drawer-content ul.nav-rails {
    display: none;
  }


  .toolbar-ref {
    height: 56px;
    width: 100%;
    position: relative;
  }

  html .mobile .mat-toolbar {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 99;

    button {
      z-index: 1;
    }

    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
      top: 0;
      height: 56px;
      line-height: 56px;
    }
  }
}
