html, body {
  h1, h2, h3, h4 {
    &.section-title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: #333;
    }
  }
}
