ul.list {
  list-style: none;
  padding-left: 0;
  overflow-y: auto;

  li {
    padding: 16px 24px;

    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;

      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
      overflow: hidden;

      &.space {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .p12 {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 145.455% */
      letter-spacing: 0.5px;
      color: #919094;

      &.space {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
