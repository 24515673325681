/* Code-Input Compatibility */
/* By WebCoder49 */
/* First Published on CSS-Tricks.com */


code-input {
  /* Allow other elements to be inside */
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  top: 0;
  left: 0;

  /* Normal inline styles */
  margin: 8px;
  --padding: 16px;
  height: 250px;
  font-size: inherit;
  font-family: monospace;
  line-height: 1.5; /* Inherited to child elements */
  tab-size: 2;
  caret-color: darkgrey;
  white-space: pre;
  padding: 0!important; /* Use --padding to set the code-input element's padding */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}


code-input:not(.code-input_loaded) {
  margin: 0px!important;
  margin-bottom: calc(-1 * var(--padding, 16px))!important;
  padding: var(--padding, 16px)!important;
  border: 0;
}

code-input textarea, code-input:not(.code-input_pre-element-styled) pre code, code-input.code-input_pre-element-styled pre {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 0px!important;
  padding: var(--padding, 16px)!important;
  border: 0;
  min-width: calc(100% - var(--padding, 16px) * 2);
  min-height: calc(100% - var(--padding, 16px) * 2);
  overflow: hidden;
  resize: none;
  grid-row: 1;
  grid-column: 1;
  display: block;
}
code-input:not(.code-input_pre-element-styled) pre code, code-input.code-input_pre-element-styled pre {
  height: max-content;
  width: max-content;
}

code-input:not(.code-input_pre-element-styled) pre, code-input.code-input_pre-element-styled pre code {
  /* Remove all margin and padding from others */
  margin: 0px!important;
  padding: 0px!important;
  width: 100%;
  height: 100%;
}

code-input textarea, code-input pre, code-input pre * {
  /* Also add text styles to highlighing tokens */
  font-size: inherit!important;
  font-family: inherit!important;
  line-height: inherit!important;
  tab-size: inherit!important;
}

code-input textarea, code-input pre {
  /* In the same place */
  grid-column: 1;
  grid-row: 1;
}

/* Move the textarea in front of the result */

code-input textarea {
  z-index: 1;
}
code-input pre {
  z-index: 0;
}

code-input:not(.code-input_loaded) pre, code-input:not(.code-input_loaded) textarea {
  opacity: 0;
}
code-input:not(.code-input_loaded)::before {
  color: #ccc;
}

/* Make textarea almost completely transparent, except for caret and placeholder */

code-input textarea {
  color: transparent;
  background: transparent;
  caret-color: inherit!important; /* Or choose your favourite color */
}
code-input textarea::placeholder {
  color: lightgrey;
}

/* Can be scrolled */
code-input textarea, code-input pre {
  white-space: inherit;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
}

/* No resize on textarea; stop outline */
code-input textarea {
  resize: none;
  outline: none!important;
}

code-input:not(.code-input_registered)::before {
  /* Display message to register */
  content: "Use codeInput.registerTemplate to set up.";
  display: block;
  color: grey;
}

/* Contains dialog boxes that might appear as the result of a plugin.
Sticks to the top of the code-input element */
code-input .code-input_dialog-container {
  z-index: 2;
  
  position: sticky;
  grid-row: 1;
  grid-column: 1;
  
  top: 0px;
  left: 0;
  width: 100%;
  height: 0;

  /* Dialog boxes' text is left-aligned */
  text-align: left;
}