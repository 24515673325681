
table {
  width: 100%;

  tr.expand-detail-row {
    height: 0;
    overflow: hidden;
  }

  .expand-element-detail {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

}

p.notes {
  background: rgba(0, 0, 0, 0.02);
  margin: 0;
  padding: 12px;
  display: block;
  position: relative;
  border-left: 3px solid rgba(0, 0, 0, 0.08);
  border-right: 3px solid rgba(0, 0, 0, 0.08);

  span {
    font-weight: bold;

    &.text {
      font-weight: normal;
    }
  }
}

.line-through {
  text-decoration: line-through;
}


.list-style {
  padding-left: 0;
  list-style: none;

  &.margin-none {
    margin: 0;
  }

  &.no-margin {
    margin: 0;
  }

  .list-line {
    padding: 12px 0;

    &.short {
      padding: 6px 0;
    }

    &.group {
      padding-bottom: 0;
    }

    &.first {
      padding: 0 0 12px;
    }

    &.last {
      padding: 12px 0 0;
    }
  }

  .list-title {
    font-size: 16px;
    font-weight: bold;
  }

  .list-subtitle {
    font-size: 14px;
    font-weight: bold;
  }

  .list-p {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
  }
}
